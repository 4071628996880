<template>
    <div>
      <Header1></Header1>
      <Header></Header>
    
      <!-- banner图 -->
      <div class="banner" :style="{backgroundImage: 'url('+ getBgImgTree() +')', height: getHeight()}">
        <div class="wrap" :style="{height: getHeight()}">
          <div class="word-wrapper">
            <span class="title">小树洞</span> <br>
            <span class="dec"></span>
          </div>
        </div>
      </div>
    
      <!-- content -->
      <div class="message-board content">
        <transition-group name="fade" appear mode="out-in"  v-if="viewPorts.width>8">
          <div
            class="card"
            v-drag
            v-for="(item, index) in list"
            :key="index"
            :style="{
              top: item.top,
              left: item.left,
              zIndex: item.zIndex,
              backgroundColor:item.bgl,
              width:item.content.length<30?cardSize1.width:cardSize2.width,
              height:item.content.length<30?cardSize1.width:cardSize2.width,
            }"
            :title="item.text"
            @mouseenter="mouseenter(item, index)"
          >
            <span class="liuyan_content"> {{ item.content }}</span>
            <div class="svg_icon" >
                <!-- <i  :class="{'el-icon-goblet-square-full':true,zan_succsee:item.isLike}" @click="methodDZ(item)"/> -->
                <!-- <i  v-if="item.isZan"  class="el-icon-goblet-square-full animate" /> -->
                <span :class="{zan_succsee:item.isLike}">{{item.count}}</span>
            </div>
            
          </div>
            
        </transition-group>

          <div class="input" >
            <el-input
              type="text"
              placeholder="想说点什么呢，按回车发布"
              @keyup.enter.native="handleLogin"
              v-model.trim="inputValue"
              key="input"
              v-if="isInput"
            />
            <!-- <i class="el-icon-remove-outline"  v-if="isInput" @click="isInput = false"></i> -->
          </div>
          <i class="el-icon-circle-plus-outline add" @click="isInput = !isInput" ></i>
      </div>
    
      <!-- bottom -->
      <Version></Version>
      <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
    </div>
    </template>
    
    
    <script>
    import Header1 from "../../components/Header1.vue"  ;
    import Header from "../../components/Header.vue"  ;
    import Banner from "../../components/Banner.vue"  ;
    import Version from "../../components/Version.vue"  ;
    import * as sysTool from '../../assets/systools.js' ; 
    export default {
      name : 'TreeHole' , 
      components:{Header1 , Banner,Header , Version} , 
      data(){
        return{
          collorArr:["pink" , "#AFEEEE" , "#abf" , "#9370DB" , "#F4A460" , "#90EE90" , "#6495ED" , "	#A9A9A9" , "#DDA0DD" , "	#F0E68C"] ,
          bgCol:"#abf",
          imgUrls:[],
          treeImg:'',
          isInput:false,
          inputValue: "",
          list: [],
          loading: true,
          // 验证码开关
          captchaOnOff: true,
          // 注册开关
          register: false,
          redirect: undefined,
          color: [
            "rgb(255,192,203,.9)",
            "rgb(255,182,193,.9)",
            "rgb(221,160,221,.9)",
            "rgb(216,191,216,.9)",
            "rgb(255,160,122,.9)",
            "rgb(188,143,143,.9)",
            "rgb(240,128,128,.9)",
            "rgb(189,183,107,.9)",
            "rgb(144,238,144,.9)",
            "rgb(102,205,170,.9)",
            "rgb(176,196,222,.9)",
            "rgb(135,206,203,.9)",
            "rgb(135,206,250,.9)",
            "rgb(192,192,192,.9)",
            "rgb(152,248,152,.9)",
            "rgb(244,164,96,.9)",
            "rgb(255,182,193,.9)",
            "rgb(221,160,221,.9)",
            "rgb(238,130,238,.9)",
          ],
    
          // 便利贴的大小，根据字数不同调节大小
          cardSize1:{
            width:window.innerWidth*0.1+'px' , 
            // height:window.innerWidth*0.1+'px' 
          } , 
          cardSize2:{
            width:window.innerWidth*0.12+'px' , 
            // height:window.innerWidth*0.12+'px' 
          } ,
          // 视口大小
          viewPorts:{
            width:'' , 
            height:''
          },
          // 标签聚集的位置
          cardPosition:{
            top:'' , 
            left:''
          } , 
          // 留言表单
          message: {
            visitorName: '',
            visitorEmail: '',
            content: '' , 
            visitorIp:'' , 
            visitorArea:'',
            parentId:'' , 
            browser: '',
            os: '' 
          },
            top:'' ,  // 监听滚轮滚动方向
            showMenu2:true ,  // 向上滚动为true，向下为false
            getStyle:'0' ,  // 歌曲控件的平移距离
        }
      } ,
      methods:{
        getHeight(){
          return window.innerHeight + 'px' ; 
        } , 
        // 获取树洞的背景图片
        getTreeImg(){
          const _this = this ; 
          _this.$axios.get("/getBgAbout").then(res => {
            _this.treeImg = res.data.data ; 
            // return res.data.data ; 
          }) ; 
        }  , 
        getBgImgTree(){
          const _this = this ; 
          return _this.treeImg ; 
        },
        getList(){
            console.log("getMessage");
          // 获取数据
          this.getMessages() ; 
          // 给每条留言数据设置css位置属性
          this.list.forEach((item) => {
            this.$set(item,'top',this.getTop())
            this.$set(item,'left',this.getLeft())
            this.$set(item,'bgl',this.getBgl())
            this.$set(item,'zIndex',0)
            // this.$set(item,'border','')
            // this.$set(item,'isZan',false)
          });
        } ,
        mouseenter(item) {
          this.list.forEach((item) => {
            item.zIndex = 0;
            item.border = "";
          });
          item.zIndex = 1;
        //   item.border = "2px dashed #ccc";
        },
        // 回车事件  将留言保存到数据库
        handleLogin() {
          if (this.inputValue) {
            this.list.forEach((item) => {
                item.zIndex = 0;
                item.border = "";
            });
            this.message.content = this.inputValue ; 
            // 将该条留言信息push到留言列表中
            this.list.push({
                zIndex: 0,
                top: this.getTop(),
                left: this.getLeft(),
                border: "2px dashed #ccc",
                isLike:null,
                bgl:this.getBgl(),
                isZan : false,
                content:this.inputValue
            })
            this.inputValue = "";
            this.isInput = false ; 
          }
          // 将留言提交到后台
            var os = sysTool.GetOs() ; 
            this.message.visitorIp = sessionStorage.getItem('ip') ; 
            this.message.visitorArea = sessionStorage.getItem('area') ; 
            this.message.browser = sysTool.GetCurrentBrowser() ; 
            this.message.os = os.name ; 
            this.$axios.post('/addMessage' , this.message , {

            }).then(res => {
                // this.getMessages() ; 
                this.$message.success("发布成功！") ; 
                this.message = {} ; 
            })
        },
        // 获取所有小树洞的留言
        getMessages() {
            this.$axios.get("/getTreeList/").then(res => {
                console.log(res);
                // 获取到所有留言后，遍历数据，将留言内容push到list中
                for (let index = 0; index < res.data.data.length; index++) {
                    this.list.push({
                        zIndex: 0,
                        top: this.getTop(),
                        left: this.getLeft(),
                        border: "2px dashed #ccc",
                        isLike:null,
                        bgl:this.getBgl(),
                        isZan : false,
                        content:res.data.data[index].content
                    })
                }
                // this.list = res.data.data;
            })
        },
        randomNum(minNum,maxNum){ 
            switch(arguments.length){ 
                case 1: 
                    return parseInt(Math.random()*minNum+1,10); 
                break; 
                case 2: 
                    return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 
                break; 
                    default: 
                        return 0; 
                    break; 
            } 
        },
        getTop() {
            if (window.innerWidth > 850) {
                let min = window.innerHeight + 40 ; 
                let max = window.innerHeight + window.innerHeight*0.7 ; 
                let res = this.randomNum(min,max) ; 
                return res + "px" ;        
            }else{
                let min = 105 ; 
                let max = 185 ; 
                let res = this.randomNum(min,max) ; 
                return   res + "vh" ;     
            }
        },
        getLeft() {
            if (window.innerWidth > 850) {
                let min = window.innerWidth * 0.05 ; 
                let max = window.innerWidth *0.85 ;
                let res = this.randomNum(min,max) ; 
                return res +"px" ; 
            }else{
                let min = 2.5 ; 
                let max = 72 ; 
                let res = this.randomNum(min,max) ; 
                return   res + "vw" ;  
            }
        },
        getBgl() {
          return   this.color[Math.floor(Math.random() * 19)];
        },
        methodDZ(item){ 
            if(item.isLike) return this.$message.warning('已经点过赞了!!!')
                item.isLike = true
                item.count =item.count?item.count+1:1
                item.isZan= true
                setTimeout(()=>{
                      item.isZan= false
                },1000)
        },
    }, 
      created(){
        // this.type_name = this.$route.params.typeName ;  
        this.getTreeImg() ;
        this.getList() ;
        // this.page(this.currentPage) ; 
        // 页面加载完毕，展示为顶部
        window.scroll(0,0) ; 
    
        // 获取视口大小
        this.viewPorts.width = window.innerWidth ; 
        this.viewPorts.height = window.innerHeight ; 
    
        // 设置card的大小 和 card的聚集位置
        if(window.innerWidth > 850){
          this.cardSize1.width = window.innerWidth*0.1+'px' ;
    
          this.cardSize2.width = window.innerWidth*0.1+'px' ;
        } else{
          this.cardSize1.width =  "25vw"// window.innerWidth*0.06+'vw' ;
          this.cardSize2.width = "25vw" // window.innerWidth*0.1+'vw' ;
        }
      } ,
      directives: {
        drag(el, bindings) {
          el.onmousedown = function (e) {
            var disx = e.pageX - el.offsetLeft;
            var disy = e.pageY - el.offsetTop;
            document.onmousemove = function (e) {
              el.style.left = e.pageX - disx + "px";
              el.style.top = e.pageY - disy + "px";
            };
            document.onmouseup = function () {
              document.onmousemove = document.onmouseup = null;
            };
          };
        },
      } ,
    }
    </script>
    
    
    <style scoped>
    .banner{
      width: 100vw;
      height: 100vh;
      background-image: url("../../img/k-on1.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      /* position: absolute;
      left: 0;
      top: 0; */
      overflow: hidden;
      opacity: 1;
    }
    .banner .wrap{
      width: 100vw;
      height: 100vh;
      /* opacity: 0.5; */
      background-color: rgba( 0, 0, 0, 0.2);
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
    }
    .banner .wrap .word-wrapper{
      position: absolute;
      top: 35%;
      /* padding-top: 280px; */
    }
    .banner .wrap .word-wrapper .title{
      color: white;
      font-size: 40px;
    }
    .banner .wrap .word-wrapper .dec{
      color: white;
      font-size: 15px;
    }
    .up{
      background-color: rgba(255, 255, 255, 0.5);
      
    }
    
    
    
    /* content ========================================== */
    @media all and (min-width:850px) {
      .content{
        width: 90vw ; 
        height:80vh;
        /* background-color: pink; */
        /* border: 1px solid pink; */
        margin: 0 auto;
        margin-top: 4rem;
        margin-bottom: 10rem;
        background: rgba(255, 255, 255, 0.7);
        padding-top: 1rem;
        padding-bottom: 10rem;
        border-radius: 5px;
        /* position: relative; */
      }
      
      .message-board {
      /* height: 100%; */
      /* width: 100%; */
      /* overflow: hidden; */
      /* background-color: #d4ffc4; */
      /* position: relative; */
      /* text-align: center;  */
    }
    
    .message-board .add {
      position: fixed;
      left: 20px;
      bottom: 20px;
      font-size: 80px;
      color: #00a8ff;
      z-index: 100;
    }
    .message-board .input {
      position: fixed;
      bottom: 50px;
      left: 120px;
      z-index: 100;
    }
    .message-board .el-input {
      width: 300px;
      height: 30px;
    }
    .message-board .card {
      display: inline;
      float: left;
      position: absolute;
      text-align: left;
      padding: 10px 20px;
      box-sizing: border-box;
      /* background-size: 100%; */
      /* background-repeat: no-repeat; */
      /* background-position: 60% 60%; */
      align-items: center;
      display: flex;
    }
    
    .message-board .card span {
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      flex: 1;
      text-align: center;
      font-size: 14px;
    }
    .message-board .card .zan_succsee {
      color: #00a8ff;
    }
    
    .message-board .card .svg_icon {
      position: absolute;
      right: 50%;
      transform: translate(50%,50%);
      bottom: 50px;
      font-size: 25px;
      cursor: pointer;
      text-align: center;
    }
    .message-board .card .svg_icon span {
      font-size: 16px;
    }
    .message-board .card .svg_icon .animate {
      position: absolute;
      left: 0;
      animation: ClickLikeAni 1s ease-in-out;
    }
    .message-board .card .close {
      position: absolute;
      right: 0;
      top: 0;
      color: brown;
      display: none;
      cursor: pointer;
    }
    
    .message-board .card:hover .close {
      display: inline;
    }
    
    }
    
    
    
    @media all and (max-width:850px) {
      .content{
        width: 96vw ; 
        height: 80vh ; 
        /* background-color: pink; */
        /* border: 1px solid pink; */
        margin: 0 auto;
        margin-top: 4rem;
        margin-bottom: 10rem;
        background: rgba(255, 255, 255, 0.8);
        padding-top: 1rem;
        padding-bottom: 10rem;
        border-radius: 5px;
      }
      .content .biaoti{
        height: 2.5rem;
        line-height: 2.5rem;
        padding-left: 1rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: #333;
        border-left: .4rem solid #333;
        margin-top: 6rem;
      }
    
      .message-board {
      /* height: 100%; */
      /* width: 100%; */
      /* overflow: hidden; */
      /* background-color: #d4ffc4; */
      /* position: relative; */
      /* text-align: center;  */
    }
    
    .message-board .add {
      position: fixed;
      left: 10px;
      bottom: 30px;
      font-size: 8rem;
      color: #00a8ff;
      z-index: 100;
    }
    .message-board .input {
      position: fixed;
      bottom: 60px;
      left: 100px;
      z-index: 100;
    }
    .message-board .el-input {
      width: 250px;
      height: 30px;
    }
    .message-board .card {
      display: inline;
      float: left;
      position: absolute;
      text-align: left;
      padding: 5px 10px;
      box-sizing: border-box;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 60% 60%;
      align-items: center;
      display: flex;
    }
    
    .message-board .card span {
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      flex: 1;
      text-align: center;
    }
    .message-board .card .zan_succsee {
      color: #00a8ff;
    }
    
    .message-board .card .svg_icon {
      position: absolute;
      right: 50%;
      transform: translate(50%,50%);
      bottom: 50px;
      font-size: 25px;
      cursor: pointer;
      text-align: center;
    }
    .message-board .card .svg_icon span {
      font-size: 16px;
    }
    .message-board .card .svg_icon .animate {
      position: absolute;
      left: 0;
      animation: ClickLikeAni 1s ease-in-out;
    }
    .message-board .card .close {
      position: absolute;
      right: 0;
      top: 0;
      color: brown;
      display: none;
      cursor: pointer;
    }
    
    .message-board .card:hover .close {
      display: inline;
    }
    
    }
    </style>