var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header1'),_c('Header'),_c('div',{staticClass:"banner",style:({backgroundImage: 'url('+ _vm.getBgImgTree() +')', height: _vm.getHeight()})},[_c('div',{staticClass:"wrap",style:({height: _vm.getHeight()})},[_vm._m(0)])]),_c('div',{staticClass:"message-board content"},[(_vm.viewPorts.width>8)?_c('transition-group',{attrs:{"name":"fade","appear":"","mode":"out-in"}},_vm._l((_vm.list),function(item,index){return _c('div',{directives:[{name:"drag",rawName:"v-drag"}],key:index,staticClass:"card",style:({
          top: item.top,
          left: item.left,
          zIndex: item.zIndex,
          backgroundColor:item.bgl,
          width:item.content.length<30?_vm.cardSize1.width:_vm.cardSize2.width,
          height:item.content.length<30?_vm.cardSize1.width:_vm.cardSize2.width,
        }),attrs:{"title":item.text},on:{"mouseenter":function($event){return _vm.mouseenter(item, index)}}},[_c('span',{staticClass:"liuyan_content"},[_vm._v(" "+_vm._s(item.content))]),_c('div',{staticClass:"svg_icon"},[_c('span',{class:{zan_succsee:item.isLike}},[_vm._v(_vm._s(item.count))])])])}),0):_vm._e(),_c('div',{staticClass:"input"},[(_vm.isInput)?_c('el-input',{key:"input",attrs:{"type":"text","placeholder":"想说点什么呢，按回车发布"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleLogin.apply(null, arguments)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputValue"}}):_vm._e()],1),_c('i',{staticClass:"el-icon-circle-plus-outline add",on:{"click":function($event){_vm.isInput = !_vm.isInput}}})],1),_c('Version'),_c('el-backtop',{staticClass:"up"},[_c('i',{staticClass:"el-icon-top"})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"word-wrapper"},[_c('span',{staticClass:"title"},[_vm._v("小树洞")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"dec"})])}]

export { render, staticRenderFns }