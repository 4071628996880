  // 获取时间
  Date.prototype.format = function(fmt) { 
    var o = { 
      "M+" : this.getMonth()+1,                 //月份 
      "d+" : this.getDate(),                    //日 
      "h+" : this.getHours(),                   //小时 
      "m+" : this.getMinutes(),                 //分 
      "s+" : this.getSeconds(),                 //秒 
      "q+" : Math.floor((this.getMonth()+3)/3), //季度 
      "S"  : this.getMilliseconds()             //毫秒 
    }; 
    if(/(y+)/.test(fmt)) {
      fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    }
    for(var k in o) {
      if(new RegExp("("+ k +")").test(fmt)){
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
    }
    return fmt; 
    // 调用时参考如下方式
    // var time1 = new Date().format("yyyy-MM-dd hh:mm:ss");
    // console.log(time1);
  }  

// get browser
export function GetCurrentBrowser () {
  let ua = navigator.userAgent.toLocaleLowerCase()
  let browserType = null
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = 'IE'
  } else if (ua.match(/firefox/) != null) {
    browserType = 'firefox'
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = 'UC'
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = 'opera'
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = 'baidu'
  } else if (ua.match(/metasr/) != null) {
    browserType = 'sougou'
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = 'QQ'
  } else if (ua.match(/maxthon/) != null) {
    browserType = 'maxthon'
  } else if (ua.match(/chrome/) != null) {
    var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
    if (is360) {
      browserType = '360'
    } else {
      browserType = 'chrome'
    }
  } else if (ua.match(/safari/) != null) {
    browserType = 'Safari'
  } else {
    browserType = 'others'
  }
  return browserType
}
 
function _mime (option, value) {
  var mimeTypes = navigator.mimeTypes
  for (var mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true
    }
  }
  return false
}
 
// get os
// 获取操作系统信息  
export function GetOs(){  
  var userAgent = navigator.userAgent.toLowerCase();  
  var name = 'Unknown';  
  var version = 'Unknown';  
  if (userAgent.indexOf('win') > -1) {  
    name = 'Windows';  
  if (userAgent.indexOf('windows nt 5.0') > -1) {  
      version = 'Windows 2000';  
  } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {  
      version = 'Windows XP';  
  } else if (userAgent.indexOf('windows nt 6.0') > -1) {  
      version = 'Windows Vista';  
  } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {  
      version = 'Windows 7';  
  } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {  
      version = 'Windows 8';  
  } else if (userAgent.indexOf('windows nt 6.3') > -1) {  
      version = 'Windows 8.1';  
  } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {  
      version = 'Windows 10';  
  } else {  
      version = 'Unknown';  
  }  
  } else if (userAgent.indexOf('iphone') > -1) {  
    name = 'iPhone';  
  } else if (userAgent.indexOf('mac') > -1) {  
    name = 'Mac';  
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {  
    name = 'Unix';  
  } else if (userAgent.indexOf('linux') > -1) {  
    if (userAgent.indexOf('android') > -1) {  
      name = 'Android';  
  } else {  
      name = 'Linux';  
  }  
  } else {  
    name = 'Unknown';  
  }  
  return { name, version };  
}
// export function GetOs () {
//   let sUserAgent = navigator.userAgent.toLocaleLowerCase()
//   let isWin = (navigator.platform === 'win32') || (navigator.platform === 'windows')
//   let isMac = (navigator.platform === 'mac68k') || (navigator.platform === 'macppc') || (navigator.platform === 'macintosh') || (navigator.platform === 'macintel')
//   if (isMac) return 'Mac'
//   var isUnix = (navigator.platform === 'x11') && !isWin && !isMac
//   if (isUnix) return 'Unix'
//   var isLinux = (String(navigator.platform).indexOf('linux') > -1)
//   if (isLinux) return 'Linux'
//   if (isWin) {
//     var isWin2K = sUserAgent.indexOf('windows nt 5.0') > -1 || sUserAgent.indexOf('windows 2000') > -1
//     if (isWin2K) return 'Win2000'
//     var isWinXP = sUserAgent.indexOf('windows nt 5.1') > -1 || sUserAgent.indexOf('windows xp') > -1
//     if (isWinXP) return 'WinXP'
//     var isWin2003 = sUserAgent.indexOf('windows nt 5.2') > -1 || sUserAgent.indexOf('windows 2003') > -1
//     if (isWin2003) return 'Win2003'
//     var isWinVista = sUserAgent.indexOf('windows nt 6.0') > -1 || sUserAgent.indexOf('windows vista') > -1
//     if (isWinVista) return 'WinVista'
//     var isWin7 = sUserAgent.indexOf('windows nt 6.1') > -1 || sUserAgent.indexOf('windows 7') > -1
//     if (isWin7) return 'Win7'
//     var isWin10 = sUserAgent.indexOf('windows nt 10') > -1 || sUserAgent.indexOf('windows 10') > -1
//     if(isWin10) return 'win10'
//   }
//   if (sUserAgent.indexOf('android') > -1) return 'Android'
//   if (sUserAgent.indexOf('iphone') > -1) return 'iPhone'
//   if (sUserAgent.indexOf('symbianos') > -1) return 'SymbianOS'
//   if (sUserAgent.indexOf('windows phone') > -1) return 'Windows Phone'
//   if (sUserAgent.indexOf('ipad') > -1) return 'iPad'
//   if (sUserAgent.indexOf('ipod') > -1) return 'iPod'
//   return 'others'
// }
 
// getAddress
// {/*<script src="http://pv.sohu.com/cityjson?ie=utf-8"></script>*/}
// {/*export function GetAddress () {*/}
//   {/*return returnCitySN*/}
// {/*}*/}